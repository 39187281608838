



































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'VideoGallery'
})

export default class extends Vue {
  @Prop({ required: true }) private property: any

  private addNewItem() {
    const length = this.property.media.videos.length
    if (length && !this.property.media.videos[length - 1]) return
    this.property.media.videos.push('')
  }

  private removeItem(index: number) {
    this.property.media.videos.splice(index, 1)
  }
}
